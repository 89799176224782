import { Button } from "../../../Common/AbstractComponents/Button";

export class AuthButton {
    constructor({ authModal }) {
        this._authModal = authModal;
        
        this._init();
    }

    _init() {
        if ($('#header_auth_btn').length === 0) return false;
        
        this._authBtn = new Button({
            button: $('#header_auth_btn'),
            onClick: this._showAuthModal.bind(this),
        })
    }

    _showAuthModal() {
        this._authModal.showModal();
    }

    destroy() {
        this._authBtn.destroy();
        $('#header_auth_btn').remove();
    }

}